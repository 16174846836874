import FacilityCta from '../InContentCta/FacilityCta';
import styled from 'styled-components';

const FacilityCtaSection = () => {
    return (
        <div>
            <FacilityCtaStyles>
                <FacilityCta />
            </FacilityCtaStyles>
        </div>
    );
};
export default FacilityCtaSection;

const FacilityCtaStyles = styled.div`
    .cta h2 {
        text-align: center !important;
    }
`;
