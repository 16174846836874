import React from 'react';
import IconQuestion from 'aac-components/components/Icons/Basic/IconQuestion';
import IconChat from 'aac-components/components/Icons/Communication/IconChat';
import IconHomeHeartOutline from 'aac-components/components/Icons/Building/IconHomeHeartOutline';
import IconHospital from 'aac-components/components/Icons/Building/IconHospital';
import IconCreditCard from 'aac-components/components/Icons/Misc/IconCreditCard';
import IconMore from 'aac-components/components/Icons/Basic/IconMore';
import IconErrorOutline from 'aac-components/components/Icons/Attention/IconErrorOutline';
import IconUserHeart from 'aac-components/components/Icons/User/IconUserHeart';
import IconFileBlankOutline from 'aac-components/components/Icons/File/IconFileBlankOutline';
import {BREAKPOINT} from 'aac-components/utils/styles';

const JumpToSection = ({blocks = [], copy = '', refs = []}) => {
    const handleScroll = (index) => {
        const block = document && document.getElementById(`block-${index}`);
        block && block.scrollIntoView({behavior: 'smooth', block: 'start'});
    };

    return (
        <div className="toc">
            {copy && (
                <div className="toc__copy" dangerouslySetInnerHTML={{__html: copy}} />
            )}
            <div className="toc__title">Jump to Section:</div>
            <div className="toc__items">
                {Array.isArray(blocks) &&
                    blocks.map((block, index) => {
                        const {toc_icon = '', toc_title = ''} =
                            block?.block_toc_element || {};

                        let Icon;
                        switch (toc_icon) {
                            case 'chat':
                                Icon = IconChat;
                                break;
                            case 'question':
                                Icon = IconQuestion;
                                break;
                            case 'home_heart':
                                Icon = IconHomeHeartOutline;
                                break;
                            case 'hospital':
                                Icon = IconHospital;
                                break;
                            case 'credit_card':
                                Icon = IconCreditCard;
                                break;
                            case 'user_heart':
                                Icon = IconUserHeart;
                                break;
                            case 'exclamation_mark':
                                Icon = IconErrorOutline;
                                break;
                            case 'file_blank':
                                Icon = IconFileBlankOutline;
                                break;
                            case 'ellipsis':
                                Icon = IconMore;
                                break;
                            default:
                                Icon = IconMore;
                                break;
                        }

                        return (
                            <React.Fragment key={index}>
                                {toc_icon && toc_title ? (
                                    <div
                                        className="item"
                                        onClick={() => handleScroll(index)}>
                                        <div className="toc__item--icon">
                                            <Icon fill="#fff" width="32" height="32" />
                                        </div>
                                        <div
                                            className="toc__item--title"
                                            dangerouslySetInnerHTML={{__html: toc_title}}
                                        />
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </React.Fragment>
                        );
                    })}
            </div>
            <style jsx>
                {`
                    .toc {
                        margin-bottom: 40px;
                        padding: 0 16px 0px 16px;
                        border-bottom: 2px solid var(--gray-200);
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .toc {
                            padding: 0;
                        }
                    }
                    .toc__items {
                        display: grid;
                        grid-template-columns: 90px 90px;
                        grid-gap: 16px 24px;
                        margin: 0 auto 40px auto;
                        max-width: fit-content;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .toc__items {
                            display: flex;
                            max-width: 100%;
                            justify-content: center;
                            flex-wrap: wrap;
                            margin-bottom: 40px;
                            grid-gap: 40px;
                        }
                    }

                    .item {
                        width: auto;
                        max-width: 90px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .item {
                            max-width: 150px;
                        }
                    }

                    .toc__item--icon {
                        background: var(--interactive);
                        width: 48px;
                        height: 48px;
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0 auto 16px auto;
                        cursor: pointer;
                    }
                    .toc__item--icon:hover {
                        background: var(--interactive-400);
                        transition: all 0.25s;
                    }
                    .toc__item--title {
                        font-weight: bold;
                        text-align: center;
                        cursor: pointer;
                        font-size: 14px;
                    }
                    .toc__copy {
                        text-align: center;
                        font-size: 16px;
                        line-height: 24px;
                        max-width: 750px;
                        margin: 0 auto 40px auto;
                    }
                    .toc__title {
                        margin-bottom: 40px;
                        text-align: center;
                        font-weight: bold;
                        font-size: 24px;
                        line-height: 32px;
                    }
                `}
            </style>
        </div>
    );
};
export default JumpToSection;
