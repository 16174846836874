import React, {useEffect, useState, useContext} from 'react';
import {BREAKPOINT, MAX_WIDTH_PX} from 'aac-components/utils/styles';
import MedicallyReviewed from '../MedicallyReviewed';
import Byline from '../Byline';
import Image from 'next/image';
import Button from 'aac-components/components/Button';
import styled from 'styled-components';
import CallrailButton from '../CallrailButton';
import PagePills from '../PagePills';
import Breadcrumbs from 'aac-components/components/Breadcrumbs';
import AppContext from '../AppContext';

const Hero = (props) => {
    const {
        title: {rendered: title},
        byline_info = {},
        modified = '',
        acf: {
            hero: {
                hero_copy = '',
                hero_image: {url: hero_image = ''},
                hero_buttons = [],
                hero_background: {background_color = '', background_type = ''} = {},
                show_byline = true,
                show_breadcrumbs = true,
            } = {},
        },
        featured_image = [],
        slug = '',
    } = props;

    const {isMobile} = useContext(AppContext);

    const useBackgroundImageHero = background_type === 'background_image';

    // give some time for the blocks to load before showing buttons so we know scroll points
    const [canRenderButton, setCanRenderButton] = useState(false);
    useEffect(() => {
        const delay = setTimeout(() => {
            setCanRenderButton(true);
        }, 2000);
        return () => clearTimeout(delay);
    }, [slug]);

    const getBackgroundColor = () => {
        switch (background_color) {
            case 'white':
                return '#fff';
            case 'secondary_gradient':
                return 'linear-gradient(359.75deg,rgba(229,235,245,.6).19%,rgba(239,246,247,.6)97.73%)';
            default:
                return 'linear-gradient(179.18deg, #eff6f7 0.71%, #f8eee5 99.29%)';
        }
    };

    const renderButton = (button, index) => {
        const buttonType = hero_buttons?.[button]?.button_type;
        const showButton = hero_buttons?.[button]?.show_button;
        const theme = index > 0 ? 'invert' : 'primary';

        if (!showButton && index > 0) return null;

        switch (buttonType) {
            case 'custom':
                return (
                    <Button
                        theme={theme}
                        href={hero_buttons?.[button]?.custom_button?.button_href}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: hero_buttons?.[button]?.custom_button
                                    ?.button_text,
                            }}
                        />
                    </Button>
                );
            case 'phone':
                return (
                    <CallrailButton
                        theme={theme}
                        style={{width: '100%'}}
                        color={useBackgroundImageHero ? '#fff' : 'var(--gray-500)'}
                    />
                );
            case 'vob':
                if (typeof window !== 'undefined' && typeof document !== 'undefined') {
                    const vobForm =
                        document &&
                        document?.querySelectorAll(
                            'body > div:not(#modal-root) #vob-form',
                        )?.[0];
                    const handleVobScroll = () => {
                        if (vobForm) {
                            //vobForm.style.scrollMargin = '500px !important';

                            vobForm.scrollIntoView({
                                behavior: 'smooth',
                                block: isMobile ? 'start' : 'center',
                            });
                        }
                    };

                    return (
                        <>
                            {vobForm ? (
                                <Button onClick={handleVobScroll}>
                                    Check Insurance Coverage
                                </Button>
                            ) : (
                                <Button onClick={(e) => window?.AAC?.openSlideInSsvob(e)}>
                                    Check Insurance Coverage
                                </Button>
                            )}
                        </>
                    );
                }
            default:
                return <></>;
        }
    };

    return (
        <>
            {show_breadcrumbs && useBackgroundImageHero && (
                <BreadcrumbsStyles>
                    <Breadcrumbs />
                </BreadcrumbsStyles>
            )}
            <div className="hero">
                {show_breadcrumbs && !useBackgroundImageHero && (
                    <BreadcrumbsStyles>
                        <Breadcrumbs />
                    </BreadcrumbsStyles>
                )}
                {useBackgroundImageHero && featured_image && (
                    <Image
                        src={featured_image?.[5] || '/static/aac_hero_bg_desktop.jpg'}
                        placeholder="blur"
                        blurDataURL={
                            featured_image?.[0] || '/static/aac_hero_bg_desktop.jpg'
                        }
                        fill
                        style={{
                            objectFit: 'cover',
                            overflow: 'hidden',
                            borderRadius: '8px',
                        }}
                        sizes="100vw"
                        priority={true}
                    />
                )}
                <div className="hero__container">
                    <div className="hero__content">
                        <MedicallyReviewed bylineData={byline_info} />
                        <h1 dangerouslySetInnerHTML={{__html: title}} />
                        <div className="byline-container">
                            <PagePills />
                            {show_byline && (
                                <BylineStyles
                                    useBackgroundImageHero={useBackgroundImageHero}>
                                    <Byline
                                        contributors={byline_info}
                                        lastModified={modified}
                                    />
                                </BylineStyles>
                            )}
                        </div>
                        <div
                            className="hero__copy"
                            dangerouslySetInnerHTML={{__html: hero_copy}}
                        />
                        <div className="hero__buttons">
                            {Object.keys(hero_buttons).map((button, index) => {
                                return (
                                    <div key={button}>
                                        {canRenderButton && renderButton(button, index)}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {hero_image && !useBackgroundImageHero && (
                        <div className="hero__image">
                            <Image
                                src={hero_image}
                                fill
                                style={{objectFit: 'contain'}}
                                priority={true}
                                alt={`${title} Image`}
                                sizes={`(min-width: ${BREAKPOINT}) 100vw, 33vw`}
                            />
                        </div>
                    )}
                </div>
                <style jsx>
                    {`
                        .hero {
                            position: relative;
                            background: ${getBackgroundColor()};
                            margin: ${useBackgroundImageHero
                                ? '0 auto 40px auto'
                                : '0 0 40px 0'};
                            max-width: ${useBackgroundImageHero ? MAX_WIDTH_PX : '100%'};
                            border-radius: ${useBackgroundImageHero ? '8px' : '0'};
                        }
                        @media screen and (min-width: ${BREAKPOINT}) {
                            .hero {
                                margin: ${useBackgroundImageHero
                                    ? `0 auto 80px auto`
                                    : '0 0 80px 0'};
                            }
                        }
                        h1 {
                            color: ${useBackgroundImageHero ? '#fff' : 'var(--gray-500)'};
                            margin-bottom: 16px;
                        }
                        .hero__buttons {
                            display: grid;
                            grid-template-columns: 1fr;
                            grid-gap: 24px;
                        }
                        @media screen and (min-width: ${BREAKPOINT}) {
                            .hero__buttons {
                                grid-template-columns: repeat(2, auto);
                                align-items: flex-start;
                                justify-content: flex-start;
                                grid-gap: 16px;
                            }
                        }
                        .hero__container {
                            position: relative;
                            display: grid;
                            grid-template-columns: 1fr;
                            grid-gap: 40px;
                            padding: 40px 15px;
                            max-width: ${MAX_WIDTH_PX};
                            margin: 0 auto;
                            background: ${useBackgroundImageHero
                                ? 'linear-gradient(0deg,rgba(000, 000, 000, 0.5) 0%,rgba(000, 000, 000, 0.5) 100%)'
                                : 'none'};
                            border-radius: 0;
                        }
                        @media screen and (min-width: ${BREAKPOINT}) {
                            .hero__container {
                                grid-template-columns: 2fr 1fr;
                                grid-gap: 80px;
                                padding: ${useBackgroundImageHero
                                    ? '80px 40px'
                                    : '80px 15px'};
                                border-radius: ${useBackgroundImageHero ? '8px' : '0'};
                            }
                        }
                        .hero__image {
                            width: 100%;
                            height: auto;
                            min-height: 275px;
                            position: relative;
                        }
                        .hero__copy {
                            font-size: 16px;
                            line-height: 24px;
                            margin-bottom: 40px;
                            color: ${useBackgroundImageHero ? '#fff' : 'var(--gray-500)'};
                        }
                        .byline-container {
                            min-height: 40px;
                        }
                    `}
                </style>
            </div>
        </>
    );
};
export default Hero;

const BreadcrumbsStyles = styled.div`
    .breadcrumbs {
        margin: 0 auto 16px auto;
        padding: 16px 10px 0 10px;
        max-width: ${MAX_WIDTH_PX};
        position: relative;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .breadcrumbs {
            padding: 24px 10px 0 10px;
        }
    }
`;

export const BylineStyles = styled.div`
    .c-byline {
        font-style: normal;
        margin-bottom: 24px;
        max-width: fit-content;
    }
    .c-byline__entry {
        font-style: normal;
        font-weight: 500;
        color: ${(props) => (props?.useBackgroundImageHero ? '#fff' : 'var(--gray-500)')};
    }
    .c-byline__entry:last-child {
        font-style: italic;
        color: ${(props) => (props?.useBackgroundImageHero ? '#fff' : 'var(--gray-400)')};
    }
`;
